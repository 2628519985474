import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import conf from "common-utils/conf";
import {
  SignIn,
  ResetPassword,
  ForgotPassword,
} from "@shared/components/lib/index";
import CustomAppBar from "../src/components/CustomAppBar";
import { formatRetailerPages, formatStoreManagerPages } from "./routers";
import { useLocalStorage } from "usehooks-ts";
import { postRequestUI } from "common-utils/utils/api";

export default function RetailerApp() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [pageReload, setPageReload] = useState(false);
  const [userClaims, setUserClaims] = useState({});
  const [auth, setAuth] = useLocalStorage("auth", null);
  const history = useHistory();

  useEffect(() => {
    if (!!auth) {
      authUser();
    } else {
      cleanState();
    }
  }, [auth]);

  const cleanState = () => {
    setUserClaims({});
    setAnchorEl(null);
  };

  const signOut = async () => {
    await postRequestUI("/logout");
    cleanState();
    setAuth(null);
    history.push("/login");
  };

  const authUser = async () => {
    try {
      const { role, email } = auth;
      if (!role.userType) {
        signOut();
        return;
      }
      if (role.userType !== "retailer" && role.userType !== "storeManager") {
        signOut();
        return;
      }
      setUserClaims({
        userType: role.userType,
        retailerId: role.retailerId,
        locationId: role.defaultLocationId,
      });

      setTimeout(function () {
        window.Trengo.contact_data = {
          email: email,
          name: email,
          custom_fields: [
            {
              field_id: email,
              user_email: email,
            },
          ],
        };
        if (window.Trengo.Api && window.Trengo.Api.Widget) {
          window.Trengo.Api.Widget.render();
        }
      }, 2500);

      return;
    } catch (error) {
      signOut();
    }
  };
  const props = { ...userClaims, pageReload };
  const pages =
    userClaims.userType === "retailer"
      ? formatRetailerPages(props)
      : formatStoreManagerPages(props);

  const redirect = () => {
    if (auth) {
      return <Redirect to={`/orders`} />;
    }
    return <Redirect to={`/login`} />;
  };
  const isUser =
    userClaims.userType === "retailer" ||
    userClaims.userType === "storeManager";
  return (
    <>
      {!auth && (
        <Switch>
          <Route path={"/forgot-password/:token?"} component={ForgotPassword} />
          <Route
            path={"/reset-password/:token?"}
            component={() => <ResetPassword appUrl={conf.carrier} />}
          />
          <Route exact path={"/login"} component={SignIn} />
          <Route path="*" render={redirect} />
        </Switch>
      )}
      {isUser && (
        <>
          <CustomAppBar
            pages={pages}
            appName={"R"}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            mobileOpen={mobileOpen}
            setMobile={setMobileOpen}
            pageReload={pageReload}
            setPageReloading={setPageReload}
            handleLogout={signOut}
          />
          <Switch>
            <main>
              {pages.map((route) => (
                <Route
                  key={route.name}
                  path={route.path}
                  render={route.component}
                />
              ))}
              <Route path="*" render={redirect} />
            </main>
          </Switch>
        </>
      )}
    </>
  );
}
