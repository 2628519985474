import React, { Fragment, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import { Hidden, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import { NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import theme from "@shared/components/lib/styles/theme";
const drawerWidth = 240;
const useStyles = (theme) => ({
  bringlyLogo: {
    maxWidth: 100,
    minWidth: 100,
  },
  appBar: {
    "&.MuiPaper-root": {
      backgroundColor: "white",
      zIndex: theme.zIndex.drawer + 1,
    },
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    "& .MuiSvgIcon-root": {
      color: theme.palette.common.black,
    },
    "&.MuiButtonBase-root": {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  },
  topnav: {
    display: "none",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.white,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  navItem: {
    margin: 2,
    "& a": {
      textDecoration: "none",
      color: theme.palette.common.black,
      fontSize: 20,
      display: "flex",
    },
    "& div": {
      paddingLeft: 1,
    },
  },
  navItemDrawer: {
    margin: "16px 32px",
  },
  toolbar: theme.mixins.toolbar,
  navItemProfile: {
    textDecoration: "none",
    color: theme.palette.common.black,
    padding: 20,
    fontSize: 14,
    display: "flex",
  },
  activeItemNav: {
    fontWeight: "500",
    color: "#0635C9",
    paddingBottom: 1,
  },
  activeItemDrawer: {
    fontWeight: "500",
    color: theme.palette.common.black,
    paddingLeft: theme.spacing(1),
    borderLeft: "3px solid black",
  },
  drawerContainer: {
    height: "100vh",
    paddingTop: theme.spacing(4),
    backgroundColor: "white",
  },
  logoutButton: {},
});
const CustomAppBar = ({
  pages,
  appName,
  mobileOpen,
  setMobile,
  anchorEl,
  setAnchorEl,
  pageReload,
  setPageReloading,
  handleLogout,
}) => {
  const styles = useStyles(theme);
  useEffect(() => {
    setMobile(mobileOpen);
  }, [mobileOpen]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerClose = () => {
    setMobile(false);
  };
  const handlePageReload = (isReloadPage) => {
    if (isReloadPage) {
      setPageReloading(!pageReload);
    }
  };
  const handleDrawerToggle = () => {
    setMobile(!mobileOpen);
  };
  const drawer = (
    <List>
      {pages.map((route) => (
        <Grid sx={{ ...styles.navItem, ...styles.navItemDrawer }}>
          <NavLink
            key={route.name}
            exact
            to={route.path}
            activeStyle={styles.activeItemDrawer}
            onClick={handleDrawerClose}
          >
            {route.icon}
            {route.name}
          </NavLink>
        </Grid>
      ))}
      <Grid sx={{ ...styles.navItem, ...styles.navItemDrawer }}>
        <NavLink
          exact
          to="/"
          href="/"
          onClick={handleLogout}
          style={{ ...styles.logoutButton, ...styles.navItemDrawer }}
        >
          Log Out
        </NavLink>
      </Grid>
    </List>
  );
  const topnav = (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Grid item>
        <Grid container>
          {pages.map((route) => (
            <>
              {!route.isHidden && (
                <Grid sx={styles.navItem}>
                  <NavLink
                    key={route.name}
                    exact
                    to={route.path}
                    activeStyle={styles.activeItemNav}
                    onClick={() => handlePageReload(route.isReloadPage)}
                  >
                    {route.icon}
                    <Grid item data-testid={route.name}>
                      {route.name}
                    </Grid>
                  </NavLink>
                </Grid>
              )}
            </>
          ))}
        </Grid>
      </Grid>
      <Grid sx={styles.navItem}>
        <NavLink
          exact
          to="/"
          onClick={handleLogout}
          style={{ ...styles.logoutButton, ...styles.activeItemNav }}
        >
          Log Out
        </NavLink>
      </Grid>
    </Grid>
  );
  return (
    <>
      <AppBar position="fixed" sx={styles.appBar}>
        <Toolbar style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={styles.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Toolbar>
            <img alt="" src="blackandblue.png" style={styles.bringlyLogo} />
          </Toolbar>
          <Grid sx={styles.topnav}>{topnav}</Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={styles.drawer}
        variant="temporary"
        styles={{
          paper: styles.drawerPaper,
        }}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        anchor="left"
      >
        <Grid container flexDirection="column" sx={styles.drawerContainer}>
          <Grid container justifyContent="end">
            <IconButton onClick={handleDrawerToggle}>
              <CloseIcon style={{ fill: "#fff" }} />
            </IconButton>
          </Grid>
          {drawer}
        </Grid>
      </Drawer>
    </>
  );
};
export default CustomAppBar;
